var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['stack',{'stack--direction-row':(_vm.direction === 'row')},
                        {'stack--direction-column':(_vm.direction === 'column')},
                        {'stack--direction-columnReverse':(_vm.direction === 'columnReverse')},
                        {'stack--direction-rowReverse':(_vm.direction === 'rowReverse')},
                        {'stack--justify-left':(_vm.justify === 'left')},
                        {'stack--justify-right':(_vm.justify === 'right')},
                        {'stack--justify-center':(_vm.justify === 'center')},
                        {'stack--justify-spaceAround':(_vm.justify === 'spaceAround')},
                        {'stack--justify-spaceBetween':(_vm.justify === 'spaceBetween')},
                        {'stack--justify-spaceEvenly':(_vm.justify === 'spaceEvenly')},
                        {'stack--space-0':(_vm.space === '0')},
                        {'stack--space-1':(_vm.space === '1')},
                        {'stack--space-2':(_vm.space === '2')},
                        {'stack--space-3':(_vm.space === '3')},
                        {'stack--align-center':(_vm.align === 'center')},
                        {'stack--align-top':(_vm.align === 'top')},
                        {'stack--align-left':(_vm.align === 'left')},
                        {'stack--align-bottom':(_vm.align === 'bottom')},
                        {'stack--align-right':(_vm.align === 'right')},
                        {'stack--columns-1':(_vm.columns === '1')},
                        {'stack--columns-2':(_vm.columns === '2')},
                        {'stack--border-n':(_vm.border === 'n')},
                        {'stack--border-a':(_vm.border === 'a')},
                        {'stack--border-l':(_vm.border === 'l')},
                        {'stack--border-r':(_vm.border === 'r')},
                        {'stack--border-lr':(_vm.border === 'lr')},
                        {'stack--border-t':(_vm.border === 't')},
                        {'stack--border-b':(_vm.border === 'b')},
                        {'stack--border-tb':(_vm.border === 'tb')},
                        {'stack--wrap':(_vm.wrap)}],style:('width:' + _vm.width + '; height:' + _vm.height + ';'),attrs:{"id":_vm.id,"name":"stack"}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }