<template>
  <div :id="id" 
      name="stack" 
			:style="'width:' + width + '; height:' + height + ';'"
      :class="['stack',{'stack--direction-row':(direction === 'row')},
                        {'stack--direction-column':(direction === 'column')},
                        {'stack--direction-columnReverse':(direction === 'columnReverse')},
                        {'stack--direction-rowReverse':(direction === 'rowReverse')},
                        {'stack--justify-left':(justify === 'left')},
                        {'stack--justify-right':(justify === 'right')},
                        {'stack--justify-center':(justify === 'center')},
                        {'stack--justify-spaceAround':(justify === 'spaceAround')},
                        {'stack--justify-spaceBetween':(justify === 'spaceBetween')},
                        {'stack--justify-spaceEvenly':(justify === 'spaceEvenly')},
                        {'stack--space-0':(space === '0')},
                        {'stack--space-1':(space === '1')},
                        {'stack--space-2':(space === '2')},
                        {'stack--space-3':(space === '3')},
                        {'stack--align-center':(align === 'center')},
                        {'stack--align-top':(align === 'top')},
                        {'stack--align-left':(align === 'left')},
                        {'stack--align-bottom':(align === 'bottom')},
                        {'stack--align-right':(align === 'right')},
                        {'stack--columns-1':(columns === '1')},
                        {'stack--columns-2':(columns === '2')},
                        {'stack--border-n':(border === 'n')},
                        {'stack--border-a':(border === 'a')},
                        {'stack--border-l':(border === 'l')},
                        {'stack--border-r':(border === 'r')},
                        {'stack--border-lr':(border === 'lr')},
                        {'stack--border-t':(border === 't')},
                        {'stack--border-b':(border === 'b')},
                        {'stack--border-tb':(border === 'tb')},
                        {'stack--wrap':(wrap)}]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    align: String,
    columns: String,
    direction: String,
    justify: String,
    space: String,
    visibility: String,
    wrap: Boolean,
    width: String,
		height: String,
		border: String,
  },
  methods:{
    responsiveClasses: function() {
      let id = this.id;
      let div = document.getElementById(id);
      if(div !== null){
        let name = div.getAttribute('name');
        let options = [{prop : 'direction', name : name},
                      {prop : 'justify', name : name},
                      {prop : 'align', name : name},
                      {prop : 'space', name : name},
                      {prop : 'columns', name : name},
                      {prop : 'border', name : name},
                      {prop : 'visibility', name : name}];
        options.forEach(this.classNames)
      }
    },
  },
  beforeMount(){
    this.id = this._uid
  },
  mounted(){
    this.responsiveClasses()
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/breakpoints.scss';

.stack, .stack .stack, .stack .stack .stack, .stack .stack .stack .stack{
  align-items:center;
  display:flex;
  position:relative;

  &.stack--wrap{
    flex-wrap:wrap;
  }

  // Direction
  &.stack--direction-row{
    flex-direction:row;
  }
  &.stack--direction-rowReverse{
    flex-direction:row-reverse;
  }
  &.stack--direction-column{
    flex-direction:column;
    .stack--direction-row{
      width:100%;
    }
  }
  &.stack--direction-columnReverse{
    flex-direction:column-reverse;
  }

  // Justify
  &.stack--justify-left{
    justify-content:flex-start;
  }
  &.stack--justify-right{
    justify-content:flex-end;
  }
  &.stack--justify-center{
    justify-content:center;
  }
  &.stack--justify-spaceAround{
    justify-content:space-around;
  }
  &.stack--justify-spaceBetween{
    justify-content:space-between;
  }
  &.stack--justify-spaceEvenly{
    justify-content:space-evenly;
  }

  // Align
  &.stack--align-top{
    align-items:flex-start;
  }
  &.stack--align-left{
    align-items:flex-start;
  }
  &.stack--align-bottom{
    align-items:flex-end;
  }
  &.stack--align-right{
    align-items:flex-end;
  }
  &.stack--align-center{
    align-items:center;
  }

  // Row Space
  &.stack--space-0 > *,
  &.stack--direction-row.stack--space-0 > *{
    margin:0!important;
  }
  
  &.stack--space-1 > *,
  &.stack--direction-row.stack--space-1 > *{
    margin:0 0 0 var(--stack-spacing-small)!important;
  }
  &.stack--space-2 > *,
  &.stack--direction-row.stack--space-2 > *{
    margin:0 0 0 var(--stack-spacing-medium)!important;
  }
  &.stack--space-3 > *,
  &.stack--direction-row.stack--space-3 > *{
    margin:0 0 0 var(--stack-spacing-large)!important;
  }
  &.stack--space-0 > *:nth-child(1),
  &.stack--space-1 > *:nth-child(1),
  &.stack--space-2 > *:nth-child(1),
  &.stack--space-3 > *:nth-child(1),
  &.stack--direction-row > *:nth-child(1){
    margin:0!important;
  }

  // Row Reverse Space
  &.stack--direction-rowReverse.stack--space-0 > *{
    margin:0!important;
  }
  .stack--direction-rowReverse.stack--space-1 > *{
    margin:0 var(--stack-spacing-small) 0 0!important;
  }
  &.stack--direction-rowReverse.stack--space-2 > *{
    margin:0 var(--stack-spacing-medium) 0 0!important;
  }
  &.stack--direction-rowReverse.stack--space-3 > *{
    margin:0 var(--stack-spacing-large) 0 0!important;
  }
  &.stack--direction-rowReverse > *:nth-child(1){
    margin:0!important;
  }

  // Column & Column Reverse Space
  &.stack--direction-column.stack--space-0 > *, 
  &.stack--direction-columnReverse.stack--space-0 > *{
    margin:0!important;
  }
  &.stack--direction-column.stack--space-1 > *, 
  &.stack--direction-columnReverse.stack--space-1 > *{
    margin:var(--stack-spacing-small) 0 0 0!important;
  }
  &.stack--direction-column.stack--space-2 > *, 
  &.stack--direction-columnReverse.stack--space-2 > *{
    margin:var(--stack-spacing-medium) 0 0 0!important;
  }
  &.stack--direction-column.stack--space-3 > *, 
  &.stack--direction-columnReverse.stack--space-3 > *{
    margin:var(--stack-spacing-large) 0 0 0!important;
  }
  &.stack--direction-column > *:nth-child(1), 
  &.stack--direction-columnReverse > *:last-child{
    margin:0!important;
  }

  // Columns 
  &.stack--columns-1{
    width:100%;
    flex-wrap:wrap;
  }
  &.stack--columns-1 > *{
    flex-shrink: 0;
  }
  &.stack--columns-2{
    display:grid;
    justify-items: start;
    min-width:100%;
    grid-template-columns: 100%;
    @include breakpoint(md){
      grid-template-columns: 50% 50%;
    }
    &.stack--justify-center{
      justify-items: center;
    }
    &.stack--justify-right{
      justify-items: end;
    }
  }
  &.stack--columns-2.stack--space-1{
    column-gap: var(--stack-spacing-small);
    row-gap: var(--stack-spacing-small);
    grid-template-columns: 100%;
    @include breakpoint(md){
      grid-template-columns: calc(50% - (var(--stack-spacing-small) * 0.5)) calc(50% - (var(--stack-spacing-small) * 0.5));
    }
  }
  &.stack--columns-2.stack--space-2{
    column-gap: var(--stack-spacing-medium);
    row-gap: var(--stack-spacing-medium);
    grid-template-columns: 100%;
    @include breakpoint(md){
      grid-template-columns: calc(50% - (var(--stack-spacing-medium) * 0.5)) calc(50% - (var(--stack-spacing-medium) * 0.5));
    }
  }
  &.stack--columns-2.stack--space-3{
    column-gap: var(--stack-spacing-large);
    row-gap: var(--stack-spacing-large);
    grid-template-columns: 100%;
    @include breakpoint(md){
      grid-template-columns: calc(50% - (var(--stack-spacing-large) * 0.5)) calc(50% - (var(--stack-spacing-large) * 0.5));
    }
  }
  &.stack--columns-2 > *{
    margin:0!important;
  }

	// Borders
	&.stack--border-l{
		border-left: var(--stack-border);
	}
	&.stack--border-r{
		border-right: var(--stack-border);
	}
  &.stack--border-lr{
		border-right: var(--stack-border);
		border-left: var(--stack-border);
	}
	&.stack--border-t{
		border-top: var(--stack-border);
	}
	&.stack--border-b{
		border-bottom: var(--stack-border);
	}
  &.stack--border-tb{
		border-top: var(--stack-border);
		border-bottom: var(--stack-border);
	}
  &.stack--border-a{
		border: var(--stack-border);
	}
  &.stack--border-n{
		border: none;
	}

  // Responsive Design - Show/Hide
  &.stack--hide-xs{
    display:none;
  }
  &.stack--show-sm{
    display:none;
    @include breakpoint(sm){
      display:flex;
      &.stack--columns-2{
        display:grid;
      }
    }
  }
  &.stack--hide-sm{
    display:flex;
    @include breakpoint(sm){
      display:none;
    }
  }
  &.stack--show-md{
    display:none;
    @include breakpoint(md){
      display:flex;
      &.stack--columns-2{
        display:grid;
      }
    }
  }
  &.stack--hide-md{
    display:flex;
    @include breakpoint(md){
      display:none;
    }
  }
  &.stack--show-lg{
    display:none;
    @include breakpoint(lg){
      display:flex;
      &.stack--columns-2{
        display:grid;
      }
    }
  }
  &.stack--hide-lg{
    display:flex;
    @include breakpoint(lg){
      display:none;
    }
  }

  // Responsive Design
  @include breakpoint(sm){
    // Direction
    &.stack--direction-sm-row{
      flex-direction:row;
    }
    &.stack--direction-sm-rowReverse{
      flex-direction:row-reverse;
    }
    &.stack--direction-sm-column{
      flex-direction:column;
    }
    &.stack--direction-sm-columnReverse{
      flex-direction:column-reverse;
    }

    // Justify
    &.stack--justify-sm-left{
      justify-content:flex-start;
    }
    &.stack--justify-sm-right{
      justify-content:flex-end;
    }
    &.stack--justify-sm-center{
      justify-content:center;
    }
    &.stack--justify-sm-spaceAround{
      justify-content:space-around;
    }
    &.stack--justify-sm-spaceBetween{
      justify-content:space-between;
    }
    &.stack--justify-sm-spaceEvenly{
      justify-content:space-evenly;
    }

    // Align
    &.stack--align-sm-top{
      align-items:flex-start;
    }
    &.stack--align-sm-left{
      align-items:flex-start;
    }
    &.stack--align-sm-bottom{
      align-items:flex-end;
    }
    &.stack--align-sm-right{
      align-items:flex-end;
    }
    &.stack--align-sm-center{
      align-items:center;
    }

    // Row & Row Reverse Space
    &.stack--direction-sm-row.stack--space-0 > *, 
    &.stack--direction-sm-row.stack--space-sm-0 > *, 
    &.stack--direction-sm-row.stack--space-md-0 > *, 
    &.stack--direction-sm-row.stack--space-lg-0 > *, 
    &.stack--direction-sm-rowReverse.stack--space-0 > *, 
    &.stack--direction-sm-rowReverse.stack--space-sm-0 > *, 
    &.stack--direction-sm-rowReverse.stack--space-md-0 > *, 
    &.stack--direction-sm-rowReverse.stack--space-lg-0 > *{
      margin:0!important;
    }
    &.stack--direction-sm-row.stack--space-1 > *, 
    &.stack--direction-sm-row.stack--space-sm-1 > *, 
    &.stack--direction-sm-row.stack--space-md-1 > *, 
    &.stack--direction-sm-row.stack--space-lg-1 > *, 
    &.stack--direction-sm-rowReverse.stack--space-1 > *, 
    &.stack--direction-sm-rowReverse.stack--space-sm-1 > *, 
    &.stack--direction-sm-rowReverse.stack--space-md-1 > *, 
    &.stack--direction-sm-rowReverse.stack--space-lg-1 > *{
      margin:0 0 0 var(--stack-spacing-small)!important;
    }
    &.stack--direction-sm-row.stack--space-2 > *, 
    &.stack--direction-sm-row.stack--space-sm-2 > *, 
    &.stack--direction-sm-row.stack--space-md-2 > *, 
    &.stack--direction-sm-row.stack--space-lg-2 > *, 
    &.stack--direction-sm-rowReverse.stack--space-2 > *, 
    &.stack--direction-sm-rowReverse.stack--space-sm-2 > *, 
    &.stack--direction-sm-rowReverse.stack--space-md-2 > *, 
    &.stack--direction-sm-rowReverse.stack--space-lg-2 > *{
      margin:0 0 0 var(--stack-spacing-medium)!important;
    }
    &.stack--direction-sm-row.stack--space-3 > *, 
    &.stack--direction-sm-row.stack--space-sm-3 > *, 
    &.stack--direction-sm-row.stack--space-md-3 > *, 
    &.stack--direction-sm-row.stack--space-lg-3 > *, 
    &.stack--direction-sm-rowReverse.stack--space-3 > *, 
    &.stack--direction-sm-rowReverse.stack--space-sm-3 > *, 
    &.stack--direction-sm-rowReverse.stack--space-md-3 > *, 
    &.stack--direction-sm-rowReverse.stack--space-lg-3 > *{
      margin:0 0 0 var(--stack-spacing-large)!important;
    }
    &.stack--direction-sm-row > *:nth-child(1), 
    &.stack--direction-sm-rowReverse > *:last-child{
      margin:0!important;
    }

    // Column & Column Reverse Space
    &.stack--direction-sm-column.stack--space-0 > *, 
    &.stack--direction-sm-column.stack--space-sm-0 > *, 
    &.stack--direction-sm-column.stack--space-md-0 > *, 
    &.stack--direction-sm-column.stack--space-lg-0 > *, 
    &.stack--direction-sm-columnReverse.stack--space-0 > *, 
    &.stack--direction-sm-columnReverse.stack--space-sm-0 > *, 
    &.stack--direction-sm-columnReverse.stack--space-md-0 > *, 
    &.stack--direction-sm-columnReverse.stack--space-lg-0 > *{
      margin:0!important;
    }
    &.stack--direction-sm-column.stack--space-1 > *, 
    &.stack--direction-sm-column.stack--space-sm-1 > *, 
    &.stack--direction-sm-column.stack--space-md-1 > *, 
    &.stack--direction-sm-column.stack--space-lg-1 > *, 
    &.stack--direction-sm-columnReverse.stack--space-1 > *, 
    &.stack--direction-sm-columnReverse.stack--space-sm-1 > *, 
    &.stack--direction-sm-columnReverse.stack--space-md-1 > *, 
    &.stack--direction-sm-columnReverse.stack--space-lg-1 > *{
      margin:0 0 0 var(--stack-spacing-small)!important;
    }
    &.stack--direction-sm-column.stack--space-2 > *, 
    &.stack--direction-sm-column.stack--space-sm-2 > *, 
    &.stack--direction-sm-column.stack--space-md-2 > *, 
    &.stack--direction-sm-column.stack--space-lg-2 > *, 
    &.stack--direction-sm-columnReverse.stack--space-2 > *, 
    &.stack--direction-sm-columnReverse.stack--space-sm-2 > *, 
    &.stack--direction-sm-columnReverse.stack--space-md-2 > *, 
    &.stack--direction-sm-columnReverse.stack--space-lg-2 > *{
      margin:0 0 0 var(--stack-spacing-medium)!important;
    }
    &.stack--direction-sm-column.stack--space-3 > *, 
    &.stack--direction-sm-column.stack--space-sm-3 > *, 
    &.stack--direction-sm-column.stack--space-md-3 > *, 
    &.stack--direction-sm-column.stack--space-lg-3 > *, 
    &.stack--direction-sm-columnReverse.stack--space-3 > *, 
    &.stack--direction-sm-columnReverse.stack--space-sm-3 > *, 
    &.stack--direction-sm-columnReverse.stack--space-md-3 > *, 
    &.stack--direction-sm-columnReverse.stack--space-lg-3 > *{
      margin:0 0 0 var(--stack-spacing-large)!important;
    }
    &.stack--direction-sm-column > *:nth-child(1), 
    &.stack--direction-sm-columnReverse > *:last-child{
      margin:0!important;
    }
    // Columns 
    &.stack--columns-sm-1{
      width:100%;
      flex-wrap:wrap;
    }
    &.stack--columns-sm-1 > *{
      flex-shrink: 0;
    }
    &.stack--columns-sm-2{
      display:grid;
      grid-template-columns: 50% 50%;
      justify-items: start;
      min-width:100%;
      &.stack--space-1,
      &.stack--space-sm-1,
      &.stack--space-md-1,
      &.stack--space-lg-1{
        column-gap: var(--stack-spacing-small);
        row-gap: var(--stack-spacing-small);
        grid-template-columns: calc(50% - (var(--stack-spacing-small) * 0.5)) calc(50% - (var(--stack-spacing-small) * 0.5));
      }
      &.stack--space-2,
      &.stack--space-sm-2,
      &.stack--space-md-2,
      &.stack--space-lg-2{
        column-gap: var(--stack-spacing-medium);
        row-gap: var(--stack-spacing-medium);
        grid-template-columns: calc(50% - (var(--stack-spacing-medium) * 0.5)) calc(50% - (var(--stack-spacing-medium) * 0.5));
      }
      &.stack--space-3,
      &.stack--space-sm-3,
      &.stack--space-md-3,
      &.stack--space-lg-3{
        column-gap: var(--stack-spacing-large);
        row-gap: var(--stack-spacing-large);
        grid-template-columns: calc(50% - (var(--stack-spacing-large) * 0.5)) calc(50% - (var(--stack-spacing-large) * 0.5));
      }
    }
    &.stack--columns-sm-2 > *{
      margin:0;
    }

    // Borders
		&.stack--border-sm-l{
			border: none;
			border-left: var(--stack-border);
		}
		&.stack--border-sm-r{
			border: none;
			border-right: var(--stack-border);
		}
		&.stack--border-sm-lr{
			border: none;
			border-right: var(--stack-border);
			border-left: var(--stack-border);
		}
		&.stack--border-sm-t{
			border: none;
			border-top: var(--stack-border);
		}
		&.stack--border-sm-b{
			border: none;
			border-bottom: var(--stack-border);
		}
		&.stack--border-sm-tb{
			border: none;
			border-top: var(--stack-border);
			border-bottom: var(--stack-border);
		}
		&.stack--border-sm-a{
			border: none;
			border: var(--stack-border);
		}
		&.stack--border-sm-n{
			border: none;
		}
  }
  
  // Responsive Design
  @include breakpoint(md){
    // Direction
    &.stack--direction-md-row{
      flex-direction:row;
    }
    &.stack--direction-md-rowReverse{
      flex-direction:row-reverse;
    }
    &.stack--direction-md-column{
      flex-direction:column;
    }
    &.stack--direction-md-columnReverse{
      flex-direction:column-reverse;
    }

    // Justify
    &.stack--justify-md-left{
      justify-content:flex-start;
    }
    &.stack--justify-md-right{
      justify-content:flex-end;
    }
    &.stack--justify-md-center{
      justify-content:center;
    }
    &.stack--justify-md-spaceAround{
      justify-content:space-around;
    }
    &.stack--justify-md-spaceBetween{
      justify-content:space-between;
    }
    &.stack--justify-md-spaceEvenly{
      justify-content:space-evenly;
    }

    // Align
    &.stack--align-md-top{
      align-items:flex-start;
    }
    &.stack--align-md-left{
      align-items:flex-start;
    }
    &.stack--align-md-bottom{
      align-items:flex-end;
    }
    &.stack--align-md-right{
      align-items:flex-end;
    }
    &.stack--align-md-center{
      align-items:center;
    }

    // Row & Row Reverse Space
    &.stack--direction-md-row.stack--space-0 > *, 
    &.stack--direction-md-row.stack--space-sm-0 > *, 
    &.stack--direction-md-row.stack--space-md-0 > *, 
    &.stack--direction-md-row.stack--space-lg-0 > *, 
    &.stack--direction-md-rowReverse.stack--space-0 > *, 
    &.stack--direction-md-rowReverse.stack--space-sm-0 > *, 
    &.stack--direction-md-rowReverse.stack--space-md-0 > *, 
    &.stack--direction-md-rowReverse.stack--space-lg-0 > *{
      margin:0!important;
    }
    &.stack--direction-md-row.stack--space-1 > *, 
    &.stack--direction-md-row.stack--space-sm-1 > *, 
    &.stack--direction-md-row.stack--space-md-1 > *, 
    &.stack--direction-md-row.stack--space-lg-1 > *, 
    &.stack--direction-md-rowReverse.stack--space-1 > *, 
    &.stack--direction-md-rowReverse.stack--space-sm-1 > *, 
    &.stack--direction-md-rowReverse.stack--space-md-1 > *, 
    &.stack--direction-md-rowReverse.stack--space-lg-1 > *{
      margin:0 0 0 var(--stack-spacing-small)!important;
    }
    &.stack--direction-md-row.stack--space-2 > *, 
    &.stack--direction-md-row.stack--space-sm-2 > *, 
    &.stack--direction-md-row.stack--space-md-2 > *, 
    &.stack--direction-md-row.stack--space-lg-2 > *, 
    &.stack--direction-md-rowReverse.stack--space-2 > *, 
    &.stack--direction-md-rowReverse.stack--space-sm-2 > *, 
    &.stack--direction-md-rowReverse.stack--space-md-2 > *, 
    &.stack--direction-md-rowReverse.stack--space-lg-2 > *{
      margin:0 0 0 var(--stack-spacing-medium)!important;
    }
    &.stack--direction-md-row.stack--space-3 > *, 
    &.stack--direction-md-row.stack--space-sm-3 > *, 
    &.stack--direction-md-row.stack--space-md-3 > *, 
    &.stack--direction-md-row.stack--space-lg-3 > *, 
    &.stack--direction-md-rowReverse.stack--space-3 > *, 
    &.stack--direction-md-rowReverse.stack--space-sm-3 > *, 
    &.stack--direction-md-rowReverse.stack--space-md-3 > *, 
    &.stack--direction-md-rowReverse.stack--space-lg-3 > *{
      margin:0 0 0 var(--stack-spacing-large)!important;
    }
    &.stack--direction-md-row > *:nth-child(1), 
    &.stack--direction-md-rowReverse > *:last-child{
      margin:0!important;
    }

    // Column & Column Reverse Space
    &.stack--direction-md-column.stack--space-0 > *, 
    &.stack--direction-md-column.stack--space-sm-0 > *, 
    &.stack--direction-md-column.stack--space-md-0 > *, 
    &.stack--direction-md-column.stack--space-lg-0 > *, 
    &.stack--direction-md-columnReverse.stack--space-0 > *, 
    &.stack--direction-md-columnReverse.stack--space-sm-0 > *, 
    &.stack--direction-md-columnReverse.stack--space-md-0 > *, 
    &.stack--direction-md-columnReverse.stack--space-lg-0 > *{
      margin:0!important;
    }
    &.stack--direction-md-column.stack--space-1 > *, 
    &.stack--direction-md-column.stack--space-sm-1 > *, 
    &.stack--direction-md-column.stack--space-md-1 > *, 
    &.stack--direction-md-column.stack--space-lg-1 > *, 
    &.stack--direction-md-columnReverse.stack--space-1 > *, 
    &.stack--direction-md-columnReverse.stack--space-sm-1 > *, 
    &.stack--direction-md-columnReverse.stack--space-md-1 > *, 
    &.stack--direction-md-columnReverse.stack--space-lg-1 > *{
      margin:0 0 0 var(--stack-spacing-small)!important;
    }
    &.stack--direction-md-column.stack--space-2 > *, 
    &.stack--direction-md-column.stack--space-sm-2 > *, 
    &.stack--direction-md-column.stack--space-md-2 > *, 
    &.stack--direction-md-column.stack--space-lg-2 > *, 
    &.stack--direction-md-columnReverse.stack--space-2 > *, 
    &.stack--direction-md-columnReverse.stack--space-sm-2 > *, 
    &.stack--direction-md-columnReverse.stack--space-md-2 > *, 
    &.stack--direction-md-columnReverse.stack--space-lg-2 > *{
      margin:0 0 0 var(--stack-spacing-medium)!important;
    }
    &.stack--direction-md-column.stack--space-3 > *, 
    &.stack--direction-md-column.stack--space-sm-3 > *, 
    &.stack--direction-md-column.stack--space-md-3 > *, 
    &.stack--direction-md-column.stack--space-lg-3 > *, 
    &.stack--direction-md-columnReverse.stack--space-3 > *, 
    &.stack--direction-md-columnReverse.stack--space-sm-3 > *, 
    &.stack--direction-md-columnReverse.stack--space-md-3 > *, 
    &.stack--direction-md-columnReverse.stack--space-lg-3 > *{
      margin:0 0 0 var(--stack-spacing-large)!important;
    }
    &.stack--direction-md-column > *:nth-child(1), 
    &.stack--direction-md-columnReverse > *:last-child{
      margin:0!important;
    }
    // Columns 
    &.stack--columns-md-1{
      width:100%;
      flex-wrap:wrap;
    }
    &.stack--columns-md-1 > *{
      flex-shrink: 0;
    }
    &.stack--columns-md-2{
      display:grid;
      grid-template-columns: 50% 50%;
      justify-items: start;
      min-width:100%;
      &.stack--space-1,
      &.stack--space-sm-1,
      &.stack--space-md-1,
      &.stack--space-lg-1{
        column-gap: var(--stack-spacing-small);
        row-gap: var(--stack-spacing-small);
        grid-template-columns: calc(50% - (var(--stack-spacing-small) * 0.5)) calc(50% - (var(--stack-spacing-small) * 0.5));
      }
      &.stack--space-2,
      &.stack--space-sm-2,
      &.stack--space-md-2,
      &.stack--space-lg-2{
        column-gap: var(--stack-spacing-medium);
        row-gap: var(--stack-spacing-medium);
        grid-template-columns: calc(50% - (var(--stack-spacing-medium) * 0.5)) calc(50% - (var(--stack-spacing-medium) * 0.5));
      }
      &.stack--space-3,
      &.stack--space-sm-3,
      &.stack--space-md-3,
      &.stack--space-lg-3{
        column-gap: var(--stack-spacing-large);
        row-gap: var(--stack-spacing-large);
        grid-template-columns: calc(50% - (var(--stack-spacing-large) * 0.5)) calc(50% - (var(--stack-spacing-large) * 0.5));
      }
    }
    &.stack--columns-md-2 > *{
      margin:0;
    }
		
    // Borders
		&.stack--border-md-l{
			border: none;
			border-left: var(--stack-border);
		}
		&.stack--border-md-r{
			border: none;
			border-right: var(--stack-border);
		}
		&.stack--border-md-lr{
			border: none;
			border-right: var(--stack-border);
			border-left: var(--stack-border);
		}
		&.stack--border-md-t{
			border: none;
			border-top: var(--stack-border);
		}
		&.stack--border-md-b{
			border: none;
			border-bottom: var(--stack-border);
		}
		&.stack--border-md-tb{
			border: none;
			border-top: var(--stack-border);
			border-bottom: var(--stack-border);
		}
		&.stack--border-md-a{
			border: none;
			border: var(--stack-border);
		}
		&.stack--border-md-n{
			border: none;
		}
  }
  
  // Responsive Design
  @include breakpoint(lg){
    // Direction
    &.stack--direction-lg-row{
      flex-direction:row;
    }
    &.stack--direction-lg-rowReverse{
      flex-direction:row-reverse;
    }
    &.stack--direction-lg-column{
      flex-direction:column;
    }
    &.stack--direction-lg-columnReverse{
      flex-direction:column-reverse;
    }

    // Justify
    &.stack--justify-lg-left{
      justify-content:flex-start;
    }
    &.stack--justify-lg-right{
      justify-content:flex-end;
    }
    &.stack--justify-lg-center{
      justify-content:center;
    }
    &.stack--justify-lg-spaceAround{
      justify-content:space-around;
    }
    &.stack--justify-lg-spaceBetween{
      justify-content:space-between;
    }
    &.stack--justify-lg-spaceEvenly{
      justify-content:space-evenly;
    }

    // Align
    &.stack--align-lg-top{
      align-items:flex-start;
    }
    &.stack--align-lg-left{
      align-items:flex-start;
    }
    &.stack--align-lg-bottom{
      align-items:flex-end;
    }
    &.stack--align-lg-right{
      align-items:flex-end;
    }
    &.stack--align-lg-center{
      align-items:center;
    }

    // Row & Row Reverse Space
    &.stack--direction-lg-row.stack--space-0 > *, 
    &.stack--direction-lg-row.stack--space-sm-0 > *, 
    &.stack--direction-lg-row.stack--space-md-0 > *, 
    &.stack--direction-lg-row.stack--space-lg-0 > *, 
    &.stack--direction-lg-rowReverse.stack--space-0 > *, 
    &.stack--direction-lg-rowReverse.stack--space-sm-0 > *, 
    &.stack--direction-lg-rowReverse.stack--space-md-0 > *, 
    &.stack--direction-lg-rowReverse.stack--space-lg-0 > *{
      margin:0!important;
    }
    &.stack--direction-lg-row.stack--space-1 > *, 
    &.stack--direction-lg-row.stack--space-sm-1 > *, 
    &.stack--direction-lg-row.stack--space-md-1 > *, 
    &.stack--direction-lg-row.stack--space-lg-1 > *, 
    &.stack--direction-lg-rowReverse.stack--space-1 > *, 
    &.stack--direction-lg-rowReverse.stack--space-sm-1 > *, 
    &.stack--direction-lg-rowReverse.stack--space-md-1 > *, 
    &.stack--direction-lg-rowReverse.stack--space-lg-1 > *{
      margin:0 0 0 var(--stack-spacing-small)!important;
    }
    &.stack--direction-lg-row.stack--space-2 > *, 
    &.stack--direction-lg-row.stack--space-sm-2 > *, 
    &.stack--direction-lg-row.stack--space-md-2 > *, 
    &.stack--direction-lg-row.stack--space-lg-2 > *, 
    &.stack--direction-lg-rowReverse.stack--space-2 > *, 
    &.stack--direction-lg-rowReverse.stack--space-sm-2 > *, 
    &.stack--direction-lg-rowReverse.stack--space-md-2 > *, 
    &.stack--direction-lg-rowReverse.stack--space-lg-2 > *{
      margin:0 0 0 var(--stack-spacing-medium)!important;
    }
    &.stack--direction-lg-row.stack--space-3 > *, 
    &.stack--direction-lg-row.stack--space-sm-3 > *, 
    &.stack--direction-lg-row.stack--space-md-3 > *, 
    &.stack--direction-lg-row.stack--space-lg-3 > *, 
    &.stack--direction-lg-rowReverse.stack--space-3 > *, 
    &.stack--direction-lg-rowReverse.stack--space-sm-3 > *, 
    &.stack--direction-lg-rowReverse.stack--space-md-3 > *, 
    &.stack--direction-lg-rowReverse.stack--space-lg-3 > *{
      margin:0 0 0 var(--stack-spacing-large)!important;
    }
    &.stack--direction-lg-row > *:nth-child(1), 
    &.stack--direction-lg-rowReverse > *:last-child{
      margin:0!important;
    }

    // Column & Column Reverse Space
    &.stack--direction-lg-column.stack--space-0 > *, 
    &.stack--direction-lg-column.stack--space-sm-0 > *, 
    &.stack--direction-lg-column.stack--space-md-0 > *, 
    &.stack--direction-lg-column.stack--space-lg-0 > *, 
    &.stack--direction-lg-columnReverse.stack--space-0 > *, 
    &.stack--direction-lg-columnReverse.stack--space-sm-0 > *, 
    &.stack--direction-lg-columnReverse.stack--space-md-0 > *, 
    &.stack--direction-lg-columnReverse.stack--space-lg-0 > *{
      margin:0!important;
    }
    &.stack--direction-lg-column.stack--space-1 > *, 
    &.stack--direction-lg-column.stack--space-sm-1 > *, 
    &.stack--direction-lg-column.stack--space-md-1 > *, 
    &.stack--direction-lg-column.stack--space-lg-1 > *, 
    &.stack--direction-lg-columnReverse.stack--space-1 > *, 
    &.stack--direction-lg-columnReverse.stack--space-sm-1 > *, 
    &.stack--direction-lg-columnReverse.stack--space-md-1 > *, 
    &.stack--direction-lg-columnReverse.stack--space-lg-1 > *{
      margin:0 0 0 var(--stack-spacing-small)!important;
    }
    &.stack--direction-lg-column.stack--space-2 > *, 
    &.stack--direction-lg-column.stack--space-sm-2 > *, 
    &.stack--direction-lg-column.stack--space-md-2 > *, 
    &.stack--direction-lg-column.stack--space-lg-2 > *, 
    &.stack--direction-lg-columnReverse.stack--space-2 > *, 
    &.stack--direction-lg-columnReverse.stack--space-sm-2 > *, 
    &.stack--direction-lg-columnReverse.stack--space-md-2 > *, 
    &.stack--direction-lg-columnReverse.stack--space-lg-2 > *{
      margin:0 0 0 var(--stack-spacing-medium)!important;
    }
    &.stack--direction-lg-column.stack--space-3 > *, 
    &.stack--direction-lg-column.stack--space-sm-3 > *, 
    &.stack--direction-lg-column.stack--space-md-3 > *, 
    &.stack--direction-lg-column.stack--space-lg-3 > *, 
    &.stack--direction-lg-columnReverse.stack--space-3 > *, 
    &.stack--direction-lg-columnReverse.stack--space-sm-3 > *, 
    &.stack--direction-lg-columnReverse.stack--space-md-3 > *, 
    &.stack--direction-lg-columnReverse.stack--space-lg-3 > *{
      margin:0 0 0 var(--stack-spacing-large)!important;
    }
    &.stack--direction-lg-column > *:nth-child(1), 
    &.stack--direction-lg-columnReverse > *:last-child{
      margin:0!important;
    }
    // Columns 
    &.stack--columns-lg-1{
      width:100%;
      flex-wrap:wrap;
    }
    &.stack--columns-lg-1 > *{
      flex-shrink: 0;
    }
    &.stack--columns-lg-2{
      display:grid;
      grid-template-columns: 50% 50%;
      justify-items: start;
      min-width:100%;
      &.stack--space-1,
      &.stack--space-sm-1,
      &.stack--space-md-1,
      &.stack--space-lg-1{
        column-gap: var(--stack-spacing-small);
        row-gap: var(--stack-spacing-small);
        grid-template-columns: calc(50% - (var(--stack-spacing-small) * 0.5)) calc(50% - (var(--stack-spacing-small) * 0.5));
      }
      &.stack--space-2,
      &.stack--space-sm-2,
      &.stack--space-md-2,
      &.stack--space-lg-2{
        column-gap: var(--stack-spacing-medium);
        row-gap: var(--stack-spacing-medium);
        grid-template-columns: calc(50% - (var(--stack-spacing-medium) * 0.5)) calc(50% - (var(--stack-spacing-medium) * 0.5));
      }
      &.stack--space-3,
      &.stack--space-sm-3,
      &.stack--space-md-3,
      &.stack--space-lg-3{
        column-gap: var(--stack-spacing-large);
        row-gap: var(--stack-spacing-large);
        grid-template-columns: calc(50% - (var(--stack-spacing-large) * 0.5)) calc(50% - (var(--stack-spacing-large) * 0.5));
      }
    }
    &.stack--columns-lg-2 > *{
      margin:0;
    }
		
		
    // Borders
		&.stack--border-lg-l{
			border: none;
			border-left: var(--stack-border);
		}
		&.stack--border-lg-r{
			border: none;
			border-right: var(--stack-border);
		}
		&.stack--border-lg-lr{
			border: none;
			border-right: var(--stack-border);
			border-left: var(--stack-border);
		}
		&.stack--border-lg-t{
			border: none;
			border-top: var(--stack-border);
		}
		&.stack--border-lg-b{
			border: none;
			border-bottom: var(--stack-border);
		}
		&.stack--border-lg-tb{
			border: none;
			border-top: var(--stack-border);
			border-bottom: var(--stack-border);
		}
		&.stack--border-lg-a{
			border: none;
			border: var(--stack-border);
		}
		&.stack--border-lg-n{
			border: none;
		}
  }

 

}
</style>